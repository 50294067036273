import {environment} from '../../../environments/environment';

export class GlobalVariables {

  setGlobalVariables(variables: any, token: string) {
    this.UserId = variables.id;
    this.UserName = variables.fullName;
    this.CompanyType = variables.companyType;
    this.UserCompanySelected = variables.companyId;
    this.Companies = JSON.stringify(variables.companies);
    this.Token = token;
    this.AccessOrigem = variables.accesOrigem;
  }

  resetAll() {
    localStorage.removeItem('Specialty.GlobalVariables.UserId');
    localStorage.removeItem('Specialty.GlobalVariables.UserName');
    localStorage.removeItem('Specialty.GlobalVariables.CompanyType');
    localStorage.removeItem('Specialty.GlobalVariables.UserCompanySelected');
    localStorage.removeItem('Specialty.GlobalVariables.UserCompanies');
    localStorage.removeItem('Specialty.token');
    localStorage.removeItem('Specialty.GlobalVariables.Permissions');
    localStorage.removeItem('Specialty.jsPlumb');
    localStorage.removeItem('Specialty.jsplumb.remove');
    localStorage.removeItem('Specialty.businessunitdistributor');
    localStorage.removeItem('Specialty.businessunitcompany');
    localStorage.removeItem('Specialty.returnUrl');
    localStorage.removeItem('BIDDING_PAGE_FILTER');
    localStorage.removeItem('BIDDING_FILTER');
    localStorage.removeItem('BIDDING_FILTER_FIELD');
    localStorage.removeItem('BIDDING_FILTER_ORDER');
    localStorage.removeItem('BIDDING_WORKFLOW_FILTER');
    localStorage.removeItem('BIDDING_DISTRIBUTORFILTER_FILTER');
    localStorage.removeItem('BIDDING_UF_FILTER');
    localStorage.removeItem('BIDDING_STATUSATUALFILTER_FILTER');
    localStorage.removeItem('BIDDING_JOB_PAGE_FILTER');
    localStorage.removeItem('BIDDING_JOB_FILTER');
    localStorage.removeItem('WORKFLOW_PAGE_FILTER');
    localStorage.removeItem('WORKFLOW_FILTER');
    localStorage.removeItem('WORKFLOW_STATUS_PAGE_FILTER');
    localStorage.removeItem('WORKFLOW_STATUS_FILTER');
    localStorage.removeItem('BSUNIT_PAGE_FILTER');
    localStorage.removeItem('BSUNIT_FILTER');
    localStorage.removeItem('CLIENT_PAGE_FILTER');
    localStorage.removeItem('CLIENT_FILTER');
    localStorage.removeItem('COMPANY_PAGE_FILTER');
    localStorage.removeItem('COMPANY_FILTER');
    localStorage.removeItem('DISTRIBUTOR_PAGE_FILTER');
    localStorage.removeItem('DISTRIBUTOR_FILTER');
    localStorage.removeItem('DISTRI_GROUP_PAGE_FILTER');
    localStorage.removeItem('DISTRI_GROUP_FILTER');
    localStorage.removeItem('FABRICANTE_PAGE_FILTER');
    localStorage.removeItem('FABRICANTE_FILTER');
    localStorage.removeItem('PARTICIPANT_PAGE_FILTER');
    localStorage.removeItem('PARTICIPANT_FILTER');
    localStorage.removeItem('PRICE_PAGE_FILTER');
    localStorage.removeItem('PRICE_FILTER');
    localStorage.removeItem('PRODUCT_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_FILTER');
    localStorage.removeItem('PRODUCT_FAMILY_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_FAMILY_FILTER');
    localStorage.removeItem('PRODUCT_CLASS_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_CLASS_FILTER');
    localStorage.removeItem('ORDER_PAGE_FILTER');
    localStorage.removeItem('ORDER_FILTER');

  }

  resetAllFilters() {
    localStorage.removeItem('BIDDING_PAGE_FILTER');
    localStorage.removeItem('BIDDING_FILTER');
    localStorage.removeItem('BIDDING_FILTER_FIELD');
    localStorage.removeItem('BIDDING_FILTER_ORDER');
    localStorage.removeItem('BIDDING_WORKFLOW_FILTER');
    localStorage.removeItem('BIDDING_DISTRIBUTORFILTER_FILTER');
    localStorage.removeItem('BIDDING_UF_FILTER');
    localStorage.removeItem('BIDDING_STATUSATUALFILTER_FILTER');
    localStorage.removeItem('BIDDING_JOB_PAGE_FILTER');
    localStorage.removeItem('BIDDING_JOB_FILTER');
    localStorage.removeItem('WORKFLOW_PAGE_FILTER');
    localStorage.removeItem('WORKFLOW_FILTER');
    localStorage.removeItem('WORKFLOW_STATUS_PAGE_FILTER');
    localStorage.removeItem('WORKFLOW_STATUS_FILTER');
    localStorage.removeItem('BSUNIT_PAGE_FILTER');
    localStorage.removeItem('BSUNIT_FILTER');
    localStorage.removeItem('CLIENT_PAGE_FILTER');
    localStorage.removeItem('CLIENT_FILTER');
    localStorage.removeItem('COMPANY_PAGE_FILTER');
    localStorage.removeItem('COMPANY_FILTER');
    localStorage.removeItem('DISTRIBUTOR_PAGE_FILTER');
    localStorage.removeItem('DISTRIBUTOR_FILTER');
    localStorage.removeItem('DISTRI_GROUP_PAGE_FILTER');
    localStorage.removeItem('DISTRI_GROUP_FILTER');
    localStorage.removeItem('FABRICANTE_PAGE_FILTER');
    localStorage.removeItem('FABRICANTE_FILTER');
    localStorage.removeItem('PARTICIPANT_PAGE_FILTER');
    localStorage.removeItem('PARTICIPANT_FILTER');
    localStorage.removeItem('PRICE_PAGE_FILTER');
    localStorage.removeItem('PRICE_FILTER');
    localStorage.removeItem('PRODUCT_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_FILTER');
    localStorage.removeItem('PRODUCT_FAMILY_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_FAMILY_FILTER');
    localStorage.removeItem('PRODUCT_CLASS_PAGE_FILTER');
    localStorage.removeItem('PRODUCT_CLASS_FILTER');
    localStorage.removeItem('ORDER_PAGE_FILTER');
    localStorage.removeItem('ORDER_FILTER');
  }

  get CompanyTypeDescription() {
    return (this.CompanyType === 'SP' ? 'Specialty' : (this.CompanyType === 'DI' ? 'Distribuidor' : 'Empresa'));
  }

  get isSpecialtyUser(): boolean {
    return this.CompanyType === 'SP';
  }

  get isCompany(): boolean {
    return this.CompanyType === 'EM';
  }

  get isDistributor(): boolean {
    return this.CompanyType === 'DI';
  }

  get UserId(): string {
    const userId = localStorage.getItem('Specialty.GlobalVariables.UserId');
    return userId ? userId : '';
  }

  set UserId(id) {
    localStorage.setItem('Specialty.GlobalVariables.UserId', id);
  }

  get UserName() {
    const username = localStorage.getItem('Specialty.GlobalVariables.UserName');
    return username ? username : '';
  }

  set UserName(name: string) {
    localStorage.setItem('Specialty.GlobalVariables.UserName', name);
  }

  get CompanyType(): string {
    const type = localStorage.getItem('Specialty.GlobalVariables.CompanyType');
    return type || '';
  }

  set CompanyType(type: string) {
    localStorage.setItem('Specialty.GlobalVariables.CompanyType', type);
  }

  get UserCompanySelected() {
    const companyId = localStorage.getItem('Specialty.GlobalVariables.UserCompanySelected');
    return companyId || '';
  }

  set UserCompanySelected(companyId: number | string) {
    localStorage.setItem('Specialty.GlobalVariables.UserCompanySelected', companyId.toString());
  }


  get Companies() {
    return localStorage.getItem('Specialty.GlobalVariables.UserCompanies');
  }

  set Companies(companyList: any) {
    localStorage.setItem('Specialty.GlobalVariables.UserCompanies', companyList);
  }

  setCompanies(companyList: any) {
    localStorage.setItem('Specialty.GlobalVariables.UserCompanies', companyList);
  }

  get Token() {
    const token = localStorage.getItem('Specialty.token');
    return token || '';
  }

  set Token(accessToken: string) {
    localStorage.setItem('Specialty.token', accessToken);
  }

  get AccesOrigem() {
    //  if(!isNullOrUndefined(localStorage.getItem('Specialty.AccesOrigem'))){
    //    return localStorage.getItem('Specialty.AccesOrigem');
    //  }else{
    return environment.redirectUrl;
    //  }
  }

  set AccessOrigem(AccesOrigem: string) {
    localStorage.setItem('Specialty.AccesOrigem', AccesOrigem);
  }

  set Permission(lst) {
    localStorage.setItem('Specialty.GlobalVariables.Permissions', JSON.stringify(lst));
  }

  get Permission() {
    const permissions = localStorage.getItem('Specialty.GlobalVariables.Permissions');
    return permissions ? JSON.parse(permissions) : null;
  }


  getItem(item: string): string | null {
    return localStorage.getItem(item);
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  AllowView(pageId: number) {
    const permissions: any[] = this.Permission;
    const page = permissions.filter(f => f.pageId === pageId);
    if (page !== null && page.length === 1) {
      return page[0].view;
    }
    return false;
  }

  AllowInsert(pageId: number) {
    const permissions: any[] = this.Permission;
    const page = permissions.filter(f => f.pageId === pageId);
    if (page !== null && page.length === 1) {
      return page[0].insert;
    }
    return false;
  }

  AllowUpdate(pageId: number) {
    const permissions: any[] = this.Permission;
    const page = permissions.filter(f => f.pageId === pageId);
    if (page !== null && page.length === 1) {
      return page[0].update;
    }
    return false;
  }

  AllowDelete(pageId: number) {
    const permissions: any[] = this.Permission;
    const page = permissions.filter(f => f.pageId === pageId);
    if (page !== null && page.length === 1) {
      return page[0].delete;
    }
    return false;
  }
}
