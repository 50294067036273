import { environment } from 'src/environments/environment';
import { Menu } from './menu.model';


export const MenuData = [
  new Menu(1, 0, 'Página Inicial', '/', null, 'home', null, false, 0),

  new Menu(2, 0, 'Usuários', null, null, 'perm_identity', null, true, 0),
  new Menu(3, 1, 'Usuários', null, environment.sso.web + '/system-user/users', '', null, false, 2, true),
  new Menu(4, 2, 'Grupo de Usuários', null, environment.sso.web + '/system-user/user-groups', '', null, false, 2, true),
  new Menu(5, 3, 'Permissões de Segurança', null, environment.sso.web + '/system-user/permissions', '', null, false, 2, true),
  new Menu(6, 4, 'Configurações de senha', null, environment.sso.web + '/system-user/password-settings', '', null, false, 2, true),

  new Menu(7, 0, 'Formulários', null, null, 'text_fields', null, true, 0),
  new Menu(8, environment.categoryid, 'Categorias', '/category', null, '', null, false, 7),
  new Menu(9, environment.formId, 'Gerenciar Formulários', '/form', null, '', null, false, 7),
  new Menu(10, environment.formFillId, 'Preencher Formulários', '/form/fill', null, '', null, false, 7), //ToDO

  new Menu (11, 0, 'Workflow', null, null, 'pages', null, true, 0),
  new Menu (12, environment.workflowStatusId, 'Status do Workflow', '/workflowstatus', null, '', null, false, 11),
  new Menu (13, environment.workflowId, 'Workflow', '/workflow', null, '', null, false, 11),

  new Menu (14, 0, 'Integração', null, null, 'pages', null, true, 0),
  new Menu (15, environment.integration, 'Integração', '/integration', null, '', null, false, 14),
]
