import { Component } from '@angular/core';
import {ActivatedRoute, Router, ActivatedRouteSnapshot, NavigationEnd, Data} from "@angular/router";
import { Title } from '@angular/platform-browser';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    providers: [MenuService]
})
export class BreadcrumbComponent {

    public pageTitle: string;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    public settings: Settings;
    constructor(public appSettings: AppSettings,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title,
        private menuService: MenuService) {
        this.settings = this.appSettings.settings;
        this.breadcrumbs = [];
        this.parseRoute(this.activatedRoute.snapshot.data);
        this.pageTitle = "";
        this.breadcrumbs.forEach(breadcrumb => {
            this.pageTitle += ' / ' + breadcrumb.name;
        })
        this.title.setTitle(this.settings.name + this.pageTitle);
    }

    nagivateTo(url: string) {
      if (url?.trim()) {
        this.router.navigate([url]);
      }
    }

    private parseRoute(data: Data) {
      const breadcrumb = data['breadcrumb'];
        if (breadcrumb) {
          for (const x in breadcrumb) {
            const name = breadcrumb[x]?.label;
            const url = breadcrumb[x]?.route;
            this.breadcrumbs.push({ name, url });
          }
        }
    }

    public closeSubMenus() {
        if (this.settings.menu == "vertical")
            this.menuService.closeAllSubMenus();
    }


}
