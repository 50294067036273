export const environment = {
  production: true,
  redirectUrl: '',
  isHomolog: false,
  showLayoutSettings: false,
  // pageId
  categoryid: 31,
  formId: 32,
  formFillId: 33,
  workflowStatusId: 34,
  workflowId: 35,
  integration: 58,

  sFlowUrl: 'https://prd-specialty-sflow-api.azurewebsites.net/api/v1',
  sMessengerUrl: 'https://prd-specialty-smessenger-api.azurewebsites.net/api',
  serviceUrl: 'https://prd-specialty-sflow-api.azurewebsites.net/api/',
  sso: {
    api: 'https://prd-specialty-sso-api.azurewebsites.net/api',
    web: 'https://www.specialty.com.br'
  }
};
