import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PagesComponent} from "./pages/pages.component";
import {PagesGuard} from "./shared/guard/pages.guard";
import {AuthorizeComponent} from "./theme/components/authorize/authorize.component";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [PagesGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        pathMatch: 'full'
      },
      {
        path: 'category',
        loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule),
      },
      {
        path: 'form',
        loadChildren: () => import('./pages/form/form.module').then(m => m.FormModule),
      },
      {
        path: 'workflowstatus',
        loadChildren: () => import('./pages/workflow-status/workflow.status.module').then(m => m.WorkflowStatusModule),
      },
      {
        path: 'workflow',
        loadChildren: () => import('./pages/workflow/workflow.module').then(m => m.WorkflowModule)
      },
      {
        path: 'integration',
        loadChildren: () => import('./pages/integration/integration.module').then(m => m.IntegrationModule)
      }
    ]
  },
  {
    path: 'authorize',
    component: AuthorizeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
